import { createStyles } from '@material-ui/core/styles';

import { colors } from '@core/theme/units/palette';

export const styles = () =>
  createStyles({
    root: {
      fill: 'transparent',
      color: colors.black.darker,
    },
  });
